import React from 'react';
import { graphql } from 'gatsby';

import { helmetPageValues as hel } from '../utils/constants';
import { Layout, ContactLayout } from '../layouts';
import { GridText, Helmet } from '../components';
import GridSubMain from '../components/shared/GridSubMain';
import GridSubOne from '../components/shared/GridSubOne';
import GridSubTwo from '../components/shared/GridSubTwo';

const paragraph = `Weather your main audience is on Instagram, Facebook, 
  Google or Viber, we can reach them. There is no limit for reaching people on t
  he internet, and we are the ones knocking on the right doors.`;

const subtitle = `Endless poll of potential customers for your business.`;

const Marketing = ({ data: { fluid: { childImageSharp } }}) => {
  return (
    <Layout>
      <Helmet {...hel.marketing} />
      <ContactLayout page="" >
        <GridSubMain columns="3fr 5fr">
          <GridSubOne image={childImageSharp.fluid} />
          <GridSubTwo>
            <GridText
              isInverse
              heading="Marketing"
              nodes={['Google', 'Facebook', 'Viber']}
              paragraph={paragraph}
              subtitle={subtitle}
            />
          </GridSubTwo>
        </GridSubMain>
      </ContactLayout>
    </Layout>
  )
}

export const query = graphql`
  {
    fluid: file(relativePath: {eq: "marketing.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Marketing;
